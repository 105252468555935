import React, { useEffect, useState } from 'react'
import './CategoryFilterModal.scss'
import CloseIcon from '../../../components/assets/images/close_icon.svg'
import { useParams } from 'react-router';
import { mainApi } from '../../../../store/services/mainApi';
import { Checkbox, CircularProgress, Switch } from '@mui/material';
import FilterModalElement from './FilterModalElement/FilterModalElement';

const CategoryFilterModal = ({setFilterCategory, filterCategory}) => {
  const tradeName = useParams().tradeName

  const { data: orderData, isLoading: isLoadingOrder } = mainApi.useGetOrderItemsByTradeNameQuery(tradeName);
  const [mapList, setMapList] = useState([]);
  useEffect(() => {
    if (!isLoadingOrder && orderData !== undefined) {
      const array = orderData
      setMapList([...array]?.sort(
        function(a, b) {
          if (a.name < b.name) return -1
        }
      ));
    }
  }, [orderData, localStorage])
  
  const [change, setChange] = useState(false);

  // const handleChange = () => {
  //   setChange(!change)
  //   if (!inFilter) {
  //     let array = localStorage.getItem('FilteredCatalog') ? JSON.parse(localStorage.getItem('FilteredCatalog')) : []
  //     array.push(item.sku)
  //     console.log(array);
  //     localStorage.setItem('FilteredCatalog', JSON.stringify(array))
  //   } else {
  //     localStorage.setItem('FilteredCatalog', JSON.stringify(localProductsFilter?.filter((el) => el !== item.sku)));
  //   }
  // }

  const array = localStorage.getItem('FilteredCatalog') ? JSON.parse(localStorage.getItem('FilteredCatalog')) : []
  return (
    filterCategory ?
    <div className='CategoryFilterModal__background' onClick={() => {setFilterCategory(false); location.reload();}}>
      <div className='CategoryFilterModal' onClick={(e) => e.stopPropagation()}>
        <div className='CategoryFilterModal__header'>
          <span>Ocultar del catálogo</span>
          <div
          onClick={() => {setFilterCategory(false); location.reload();}}
          >
            <img src={CloseIcon} alt="" />
          </div>
        </div>
        <div className='CategoryFilterModal__body'>
          <div className='CategoryFilterModal__body__item'>
            <div className='CategoryFilterModal__body__item__data'>
              
            </div>
            <Checkbox
              className='previewProfile__right__paymentType__elem__row__check'
              checked={array.length !== orderData?.length}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E', mr: 1 } }}
              onChange={() => {
                
                if (array.length == orderData?.length) {
                  setChange([])
                  localStorage.setItem('FilteredCatalog', JSON.stringify([]))
                  console.log('dd');
                } else {
                  console.log('gg');
                  localStorage.setItem('FilteredCatalog', JSON.stringify([...orderData.map((item) => item.sku)]));
                }
                location.reload()
              }}
            />
          </div>
          {isLoadingOrder ? <CircularProgress/> :
            mapList?.map((item) => item?.enabled == 1 &&
            <FilterModalElement setChange={setChange} change={change} item={item} key={'CategoryFilterModal__body__item__'+item?._id}/>)
          }
        </div>
      </div>
    </div>
    : <></>
  )
}

export default CategoryFilterModal