import React, { useState } from 'react'
import unitEqual from '../../../../constants/unitEqual'
import { Checkbox } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { setItemModal } from '../../../../store/slices/contextUI';
import ItemCounterModal from '../../ModalWindows/ItemCardModal/ItemCounterModal/ItemCounterModal';
import './TableCard.scss'
import useFindItemByUnit from '../../../../hooks/useFindItemByUnit';
import useFindInCart from '../../../../hooks/useFindInCart';


const TableCard = ({ item }) => {
  const dispatch = useDispatch()
  const inCart = useFindInCart(item)

  const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)
  const [grammMode, setGramMode] = useState(false)

  const total = (item?.marketPriceCP* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2)
  const ivaTotal = ((item?.marketPriceCP * useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0]) * inCart?.quantity || item?.quantity) / (1 + item.IVACP/100)).toFixed(2)

  return (
    <div className='cartModal__body__item' style={{flexDirection: 'column'}} key={item?.title+item?.sku}>
      <div className='cartModal__body__item__row'>
        <img src={item?.img} alt="" style={{ borderRadius: '10px'}} onClick={() => {dispatch(setItemModal({item: item, isShow: true}))}}/>
        <div className='cartModal__body__item__row__info'>
          {item?.name}
          {item?.pdt &&
          <div className='contentCard__body__subtitle__info dias'>{item?.pdt}</div>}
          {!!item?.decimal && <div className='cartModal__body__item__row__info__sku TableCard__div' >
            <span >Pedir en gramos</span>
            <Checkbox
              className='previewProfile__right__paymentType__elem__row__check'
              checked={grammMode}
              sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E', mr: 1 } }}
              onChange={() => {setGramMode(!grammMode)}}
            />  
          </div>}
        </div>
        <div style={{width: 'fit-content', textWrap: 'nowrap', color: '#6f6f6f'}}>{!ivaSwitch ? 
        item?.spoPrice ? (item?.spoPrice / (1+item.IVACP/100)).toFixed(2) : (item?.marketPriceCP / (1+item.IVACP/100)).toFixed(2)
        : item?.spoPrice ? item?.spoPrice : item?.marketPriceCP}&euro;/ {item?.currentUnit}</div>
      </div>
      <div className='cartModal__body__item__row'>
        <ItemCounterModal item={item} key={item?.sku+item?.name} grammMode={grammMode}/>
        <div>{item?.spoPrice ? <div style={{display: 'flex', flexDirection: 'column', gap: '5px'}}>
          <span style={{textDecoration: 'line-through', color: '#5FC56E'}}>
            {ivaSwitch ? total +'€' : ivaTotal +'€'}
          </span>
          <span style={{color: '#5FC56E'}}>
            {ivaSwitch ? (item?.spoPrice * (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity))).toFixed(2) +'€' :
            ((item?.spoPrice* (useFindItemByUnit(item, inCart?.currentUnit || item?.unit?.split(',')[0])*(inCart?.quantity || item?.quantity)))/(1 + item.IVACP/100)).toFixed(2) +'€'
            }
          </span>
        </div>
        :
        <span>
          {ivaSwitch ? total +'€' : ivaTotal+'€'}
        </span>
        }</div>
        
      </div>
      <div 
        className='cartModal__body__item__row'
        style={{display: 'flex', alignItems: 'center', justifyContent: 'flex-start', gap: '10px', width: 'fit' }}
      >
        {unitEqual.find((el) => el.sku === item?.sku)?.und && ('und: '+unitEqual.find((el) => el.sku === item?.sku)?.und+" kg aprox ")
        }
        {unitEqual.find((el) => el.sku === item?.sku)?.caja && ('caja: '+unitEqual.find((el) => el.sku === item?.sku)?.caja+" kg aprox ")
        }
        {unitEqual.find((el) => el.sku === item?.sku)?.saco && ('saco: '+unitEqual.find((el) => el.sku === item?.sku)?.saco+" kg aprox ")
        }
        {unitEqual.find((el) => el.sku === item?.sku)?.band && ('bandaxa: '+unitEqual.find((el) => el.sku === item?.sku)?.band+" kg aprox ")
        }
      </div>
    </div>
  )
}

export default TableCard