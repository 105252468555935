import React, { useEffect, useState } from 'react'
import './App.css'
import { RouterProvider } from 'react-router-dom'
import { routerDeleverItems } from './router'
import 'normalize.css'
import CartElemInfoModal from './pages/components/ModalWindows/CartElemInfoModal/CartElemInfoModal'
import Footer from "./pages/components/Footer/Footer";
import { useSelector } from 'react-redux'
import FavoriteModal from './pages/components/ModalWindows/FavoriteModal/FavoriteModal'
import AutoDataSynhronaizer from './pages/components/AutoDataSynhronaizer/AutoDataSynhronaizer'
import ItemCardModal from './pages/components/ModalWindows/ItemCardModal/ItemCardModal'
import WarningModal from './pages/components/ModalWindows/WarningModal/WarningModal'
import AlertModal from './pages/components/ModalWindows/AlertModal/AlertModal'
import ErrorHandler from './pages/ErrorPage/ErrorHandler'
import useSendLog from './hooks/useSendLog'
import { HelmetProvider } from 'react-helmet-async';


const App = () => {
  const openFavoriteModal = useSelector(state => state.contextUI.uiModal.favoriteModal)
  const ItemModalData = useSelector(state => state.contextUI.uiModal.itemModal)
  const [isAlertModal, setIsAlertModal] = useState(true)
  const sendUserStats = useSendLog('User stats', { 
    device: navigator.userAgent,
    typeOfDevice: navigator.platform,
    os: navigator.oscpu,
    browser: navigator.appName,
    browserVersion: navigator.appVersion
  });

  // window.addEventListener('load', function(event) {
    
  //   sendUserStats()
  // });

  return (
    <div className='App'>
      <ErrorHandler>
        <HelmetProvider>
          <div className='App__padding'>
            <RouterProvider router={routerDeleverItems} />
            
            {openFavoriteModal && <FavoriteModal/>}
            <CartElemInfoModal/>
            
            {/* {isAlertModal && 
            <AlertModal closeFunc={setIsAlertModal} sx={{ textAlign: 'start '}}>
              <div style={{width: '100%', borderRadius: '8px'}}>
                <img src={'https://i.imgur.com/CugTirk.png'} style={{width: '100%'}}/>
              </div>
              ¡<b>Atención!</b> <br/><br/>

              <b>El Sábado, día 8 de marzo</b> habrá la <b>manifestación</b> y marcha del día internacional de la mujer trabajadora <br/><br/>

              <b>NO VAMOS a tener servicio el sábado POR LA TARDE</b>, por las varias cortes de calles en Barcelona. <br/><br/>

              Por favor, hagan sus pedidos, considerando que <b>no habrá posibilidad entregar el sábado POR LA TARDE!</b> <br/><br/>

              Gracias
            </AlertModal>} */}
            <AutoDataSynhronaizer/>
            {ItemModalData.isShow ? <ItemCardModal card={ItemModalData.item}/> : <></>}
          </div>
        </HelmetProvider>
      </ErrorHandler>
        <Footer/>
    </div>
  )
}

export default App