import React, { useEffect, useMemo, useState } from 'react'
import './OwnerPage.scss'
import { ReactComponent as ShareIcon } from '../components/assets/images/OwnerPage/shareIcon.svg'
import { ReactComponent as TurnoverIcon } from '../components/assets/images/OwnerPage/turnoverIcon.svg'
import { ReactComponent as ProfitIcon } from '../components/assets/images/OwnerPage/profitIcon.svg'
import { ReactComponent as DebtIcon } from '../components/assets/images/OwnerPage/debtIcon.svg'
import { mainApi } from '../../store/services/mainApi'
import ModalUI from '../components/ModalWindows/ModalUI/ModalUI'
import { useParams } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { ReactComponent as EditIcon } from '../components/assets/images/editIcon.svg'
import { ReactComponent as SaveIcon } from '../components/assets/images/saveIcon.svg'
import LockModal from './../components/ModalWindows/LockModal/LockModal';
import { Box, Modal } from '@mui/material'
import { ReactComponent as ConfirmIcon } from '../components/assets/images/confirmIcon.svg'
import { ReactComponent as DeleteIcon } from '../components/assets/images/deleteIcon.svg'
import { isArray } from 'lodash'
import { Helmet } from 'react-helmet-async'

const OwnerPage = () => {
  const dispatch = useDispatch()
  const tradeNameParams = useParams().tradeName ;
  const [ownerData, setOwnerData] = useState(null)
  const [isEditPaid, setIsEditPaid] = useState(false)
  const [isLock, setIsLock] = useState(true)
  const [isOpenLockModal, setIsOpenLockModal] = useState(false)
  const [editOwnerCode, setEditOwnerCode] = useState(null)
  const [isSendPaymentModal, setIsSendPaymentModal] = useState(false)
  const [addPaymentData, setAddPaymentData] = useState({
    confirm: false,
    value: 0,
    description: '',
    date: new Date(),
    type: 'Tarjeta',
  })
  const [codeValuePaids, setCodeValuePaids] = useState([])
     
  const {
    data: contact,
    isLoading,
    isError,
    refetch
  } = mainApi.useGetContactAmbassadorByTradeNameAdminQuery(tradeNameParams, {
    skip: !tradeNameParams,
  })
  const {
    data: contactCode,
    isLoading: isLoadingСontactCode,
    isError: isErrorСontactCode,
    refetch: refetchСontactCode
  } = mainApi.useGetBuisnessContactByTradeNameQuery(editOwnerCode, {
    skip: !editOwnerCode,
  })

  useEffect( () => { async () => {
    console.log(contactCode.paid);
    if (!isLoadingСontactCode && contactCode!== undefined) {
      if (!isArray(contactCode.paid)) {
        const element = {...addPaymentData, value: contactCode?.paid ? contactCode.paid : 0}
        await updateCodesPaid([{
          tradeName: editOwnerCode,
          paid: [...element]
        }]).then((res) => {
          refetchСontactCode()
          setTimeout(() => {
            dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
          }, 500)
        })
      }
    }}
  }, [contactCode, isLoadingСontactCode, editOwnerCode])
  useEffect(() => {
    if (!isLoadingСontactCode && contactCode!== undefined && isArray(contactCode.paid)) {
      setCodeValuePaids(contactCode.paid);
    }
  }, [contactCode])

  const [updateCodesPaid, { data: resultUpdateCodesPaid, isSuccess: isSuccessUpdateCodesPaid }] = mainApi.useUpdatePaidByTradeNameContactAdminMutation()

  const [updateCodes, { data: resultUpdateCodes, isLoading: isLoadingUpdateCodes, isError: isErrorUpdateCodes }] = mainApi.useUpdateContactAdminMutation()
  const [dataCods, setDataCods] = useState(ownerData?.codes ? ownerData.codes : '')

  useEffect(() => {
    if (!isLoading && contact) {
      const contactOwner = contact[0]
      console.log(contactOwner);
      setOwnerData(contactOwner)
      setDataCods(contactOwner?.codes)
    }
  }, [contact])


 
  const [statsTradeNamesData, setStatsTradeNamesData] = useState([])
  // console.log(dataCods);
  const { data: statsTradeNames, isLoading: isStatsTradeNamesLoading} = mainApi.useGetStatsByTradeNamesQuery(dataCods)
  const { data: statsOrdersByWeek, isLoading: isStatsOrdersByWeek} = mainApi.useGetOrdersByWeekAdminQuery(dataCods)

  const [grafData, setGrafData] = useState([])
  useEffect(() => {
    if (!isStatsOrdersByWeek) {
      const daysSummary = Object.values(statsOrdersByWeek).reduce((acc, location) => {
        Object.entries(location).forEach(([day, orders]) => {
          acc[day] = (acc[day] || 0) + orders.length;
        });
        return acc;
      }, {});

      // 2. Создаем отсортированный массив
      const daysOrder = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      
      const result = Object.entries(daysSummary)
        .map(([name, value]) => ({ name, value }))
        .sort((a, b) => daysOrder.indexOf(a.name) - daysOrder.indexOf(b.name));
      setGrafData(result);
    }
  }, [statsOrdersByWeek])

  const [addCodeValue, setAddCodeValue] = useState('')



  useEffect(() => {
    if (!isStatsTradeNamesLoading && statsTradeNames) {
      const statsTradeNamesWithOut = statsTradeNames
      setStatsTradeNamesData([...statsTradeNamesWithOut
        ]?.sort((a, b) => a.code < b.code)?.sort((a, b) => a.turnover !== 0 ? -1 : 1));
      setSelectedCodes([...statsTradeNamesWithOut]);
    }
  }, [isStatsTradeNamesLoading, statsTradeNames]);
  // Оставляем зависимость, если statsTradeNames может обновляться

  const [selectedCodes, setSelectedCodes] = useState([])
  const [isClose, setIsClose] = useState(true)
  const [passwordValue, setPasswordValue] = useState('')



  const userData = useSelector((state) => state.accountData.userAcc)

  const handleSecure = () => {
    const userLocalData = JSON.parse(localStorage.getItem('userData'))
    // console.log(userLocalData);
    if (userLocalData.tradeNameCode == 'PBLT') {
      return true
    }
    if (userData?.tradeName) {
      console.log(tradeNameParams == userData?.tradeName ? true : false);
      return  tradeNameParams == userData?.tradeName ? true : false
    } else {
      console.log(false);
      return false
    }
  }

  const [isSecure, setIsSecure] = useState(false)
  
  useEffect(() => {
    const isLock = handleSecure()
    setIsSecure(isLock)
  }, [userData, tradeNameParams])

  const [codesValuePaid, setCodesValuePaid] = useState([])
  
  const comissionTotal = selectedCodes.reduce((acc, item) => acc + (item.turnover-item.debt)/100*5, 0)

  const comissionPaidTotal = codesValuePaid?.reduce((acc, item) => 
  acc + (isArray(item.paid) ? (item?.paid?.reduce((accum, el) =>
  parseFloat(accum)+ parseFloat(el.value), 0)): 0), 0)
  useEffect(() => {
    if (!isStatsTradeNamesLoading) {
      setCodesValuePaid(statsTradeNamesData.map((item) => ({
        tradeName: item.code,
        paid: item?.paid ? item.paid : []
      })))
      // console.log(statsTradeNamesData);
    }
  }, [statsTradeNamesData, isStatsTradeNamesLoading])

  const findValuePaid = (code) => {
    console.log(code)
    if (code == undefined) {
      return 'not code'
    }
    const findCode = codesValuePaid?.find(item => item.tradeName == code)
    // console.log(findCode);
    // return findCode == undefined ? 'not found' : 'found'
    // console.log(findCode?.paid?.reduce((acc, currentItem) => acc+currentItem.value, 0));
    
    if (!isArray(findCode?.paid)) {
      return 'error'
    }
    return findCode?.paid ? findCode.paid?.reduce((acc, item) => parseFloat(acc)+parseFloat(item.value), 0) : 0
  }

  const handleChangeValue = (e, code) => {
    setCodesValuePaid(codesValuePaid.map((item) => item.tradeName === code ? {
      tradeName: item.tradeName,
      paid: [
        {
          confirm: false,
          value: 10,
          description: '',
          date: new Date()
        },
      ]
    } : item))
  };

  const headerColumnTable = [
    { 
      field: 'code',
      headerName: 'code',
      width:  85,
      renderCell: (params) => {
        return (
          <div style={{width: '100%', paddingLeft: '5px'}}>
            <button 
              onClick={() => {window.open(`https://tpoapp.com/account/${params.value}`,'_blank')}} 
              style={{width: '80px'}}
            >
              {params.value}
              <ShareIcon width={20} height={20}/>
            </button>
          </div>
        )
      },
    },
    {
      field: 'name',
      headerName: 'name',
      width: 280,
      renderCell: (params) => {
        return (
          <div 
            style={{width: '100%', textAlign: 'start', color: params.value ? 'black' : 'gray'}}
          >
            {params.value ? params.value : 'Make Order to show name'}
          </div>
        )
      },
    },
    {
      field: 'turnover',
      headerName: 'turnover',
      width: 100,
      renderCell: (params) => (
        <div style={{width: '100%', textAlign: 'end', color: '#286f29'}}>
          {params.value ? params.value.toFixed(2) : 0} €
        </div>
      ),
    },
    { 
      field: 'turnover',
      headerName: 'paid',
      width: 100,
      renderCell: (params) => (
        <div style={{width: '100%', textAlign: 'end', color: '#5fc56e'}}>
          {(parseFloat(params.value)-parseFloat(params.row.debt)).toFixed(2)} €
        </div>
      )
    },
    {
      field: 'debt',
      headerName: 'debt',
      width: 100,
      renderCell: (params) => {
        return (
          <div style={{width: '100%', textAlign: 'end', color: '#ff725e'}}>
            {params.value ? params.value.toFixed(2) : 0} €
          </div>
        )
      },
    },
    {
      field: 'orders',
      headerName: 'orders',
      width: 52,
      renderCell: (params) => {
        return (
          <div style={{width: '100%'}}>
            {params.value ? params.value : 0}
          </div>
        )
      },
    },
    {
      field: 'orders',
      headerName: 'avg order',
      width: 80,
      renderCell: (params) => {
        return (
          <div style={{width: '100%', textAlign: 'end'}}>
            {params.value ? (params.row.turnover/params.value).toFixed(2) : 0} €
          </div>
        )
      },
    },
    {
      field: 'turnover',
      headerName: 'estimated',
      width: 120,
      renderCell: (params) => {
        return (
          <div style={{width: '100%', textAlign: 'end'}}>
            {params.value ? (params.value/100*5).toFixed(2) : 0} €
          </div>
        )
      },
    },
    {
      field: 'code',
      headerName: 'paid',
      width: 120,
      renderCell: (params) => {
        return (isEditPaid ? 
          // <input 
          //   type='number'
          //   className='OwnerPage__inputPaid'
          //   min={0}
          //   style={{width: '120px', textAlign: 'end', marginRight: '5px'}}
          //   value={(codesValuePaid?.find(item => item.tradeName === params.value)?.paid ? parseFloat(codesValuePaid.find(item => item.tradeName === params.value).paid) : 0)}
          //   onChange={(e) => handleChangeValue(e, params.value)}
          //   /> 
          <button
            style={{width: '96%'}}
            onClick={() => {
              setEditOwnerCode(params.value)
            }}
          >
            Paids
          </button>
          :
          <div style={{width: '120px', textAlign: 'end', paddingRight: '5px'}}>
            {findValuePaid(params.value)} €
          </div>
        )
      },
    },
  ] 


  return (
    <div className='OwnerPage'>
      <Helmet>
        <title>Owner Link: {tradeNameParams}</title>
        {/* <meta property="og:title" content={`Owner Link: ${tradeNameParams}`} />
        <meta property="og:description" content={`Statistick for you`} />
        <meta property="og:image" content="https://i.imgur.com/9tXrg46.png" />
        <meta property="og:url" content={"https://tpoapp.com/owner/"+tradeNameParams} />
        <meta property="og:type" content="website" /> */}
      </Helmet>
      <div className='OwnerPage__row'>
        <section>
          <div className='OwnerPage__imageBox'>
            <img src={ownerData?.img} alt="" />
          </div>
        </section>
        <section style={{width: '100%', height: '148px'}}>
          <div className='OwnerPage__infoUser'>
            <h2>{ownerData?.name}</h2>
            <p>{ownerData?.phone ? '+'+ownerData.phone : ''}</p>
            <img src={ownerData?.logo} alt="" />
            <ModalUI
              sxBtn={{
                outline: 'none',
                border: 'none',
                width: '100%',
                height: '30px',
                background: '#5fc56e',
                color: '#fff',
                fontSize: '12px',
                fontWeight: '700',
                textAlign: 'center',
                borderRadius: '8px',
                marginTop: 'auto',
                marginBottom: '5px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
              sxBackground={{backgroundColor: 'rgba(255, 255, 255, 0.12)000'}}
              sxModal={{
                borderRadius: '18px',
                border: '1px solid #5fc56e',
                paddingBottom: '5px',
                height: isClose ? '200px' : 'auto',
                minHeight: isClose ? '190px' : '300px',
                maxHeight: isClose ? '190px' : '720px',

                '.img': {
                  height: '15px',
                  width: '15px',
                }
              }}
              sx={{height: '100%', gap: '5px', display: 'flex', flexDirection: 'column'}}
            >
            {!isClose ?
              <>
                <div className='OwnerPage__infoUser__modal__codeBox'>
                  {statsTradeNamesData?.map((item, index) => (
                    <div key={item.code} className='OwnerPage__infoUser__modal__codeBox__item'>{index+1}. {item.code}</div>))
                  }
                </div>
                <div className='OwnerPage__infoUser__modal__inputBox'>
                  <input type="text" value={addCodeValue} onChange={(e) => setAddCodeValue(e.target.value)}/>
                  <button 
                    onClick={async () => {
                        // setDataCods(dataCods+','+addCodeValue)
                        await updateCodes({ data: {
                          id: ownerData.id,
                          codes: dataCods+(dataCods ? ',' :'')+addCodeValue,
                        }})
                        refetch()
                        setAddCodeValue('')
                        setIsClose(true)
                      }}
                    >+</button>
                </div>
              </> :
              <div className='OwnerPage__infoUser__modal__passwordBox'>
                <input type="number" min="0" max="9999" value={passwordValue} onChange={(e) => setPasswordValue(e.target.value)} placeholder='0000'/>
                <button onClick={() => {if (passwordValue === '2810') {setIsClose(false)} else {alert('Wrong password')}}}>Unlock</button>
              </div>
            }
            </ModalUI>
          </div>
          <div className='OwnerPage__infoStats'>
            {/* <img src="https://i.imgur.com/Ez5Y6zk.png" alt="" /> */}
            <ResponsiveContainer>
              <AreaChart width={162} height={152} data={grafData}>
                <defs>
                  <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8}/>
                    <stop offset="95%" stopColor="#82ca9d" stopOpacity={0}/>
                  </linearGradient>
                </defs>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip />
                <Area type="monotone" dataKey="value" stroke="#82ca9d" fillOpacity={1} fill="url(#colorPv)"/>
              </AreaChart>
            </ResponsiveContainer>
          </div>
        </section>
      </div>
      <div className='OwnerPage__row'>
        <section className='OwnerPage__infoBar'>
          <div className='OwnerPage__infoBar__select'>
            Selected: {selectedCodes.length}
          </div>
          <div className='OwnerPage__infoBar__turnover'>
            <TurnoverIcon width={18} height={18} />
            <span>
              Turnover: {selectedCodes.reduce((acc, item) => acc + item.turnover, 0).toFixed(2)} €
            </span>
          </div>
          <div className='OwnerPage__infoBar__profit'>
            <ProfitIcon width={18} height={18} />
            <span>
              Paid: {selectedCodes.reduce((acc, item) => acc + item.turnover-item.debt, 0).toFixed(2)} €
            </span>
          </div>
          <div className='OwnerPage__infoBar__debt'>
            <DebtIcon width={18} height={18} />
            <span>
              Debt: {selectedCodes.reduce((acc, item) => acc + item.debt, 0).toFixed(2)} €
            </span>
          </div>
          {ownerData?.owner ? <></>
           :
           <span className='OwnerPage__infoBar__persents'>
            <div className='OwnerPage__infoBar__profit'>
              Estimated: {parseFloat(comissionTotal).toFixed(2)} €
            </div>
            <div className='OwnerPage__infoBar__profit'>
              Paid: {parseFloat(comissionPaidTotal).toFixed(2)} €
            </div>
          </span>
          }
        </section>
      </div>
      <section className='OwnerPage__editBar'>
        <button 
          style={{
            width: '30px',
            height: '30px',
            background: '#5FC56E',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            outline: 'none',
            border: 'none'
          }}
          onClick={async () => {
            if (isLock) {  
              setIsOpenLockModal(true)
            } else {
              setIsEditPaid(!isEditPaid)
              if (isEditPaid) {
                console.log(codesValuePaid);
                // await updateCodesPaid(codesValuePaid)
              }
            }
          }}
        >
          {isEditPaid ? <SaveIcon /> : <EditIcon />}
        </button>
      </section>
      <div className='OwnerPage__row'>
        {!isStatsTradeNamesLoading &&
        <section className='OwnerPage__table' style={{ padding: '5px 15px'}}>
            <div className='OwnerPage__table__row' style={{height: '20px', marginBottom: '0px'}}>
              <div style={{width: '40px'}}>
                <input type="checkbox" onClick={() => {
                  if (selectedCodes.length !== statsTradeNamesData.length) {
                    setSelectedCodes(statsTradeNamesData)
                  } else {
                    setSelectedCodes([])
                  }
                }} checked={selectedCodes.length == statsTradeNamesData.length}/>
              </div>
              <div className='OwnerPage__table__row__background' style={{backgroundColor: '#fff'}}>
                {headerColumnTable.slice(0, !ownerData?.owner ? 9 : 7).map((item, index) => (
                  <div key={index} style={{width: item.width}} className='OwnerPage__table__row__headerColumn'>{item.headerName}</div>))}
              </div>
            </div>
            {statsTradeNamesData?.map((item, index) => (
              <div key={index} className='OwnerPage__table__row'>
                <div style={{width: '40px', position: 'sticky', left: 0, zIndex: 1 }}>
                  <input type="checkbox" onClick={() => {
                    if (selectedCodes.map((el) => el.code).includes(item.code)) {
                      setSelectedCodes(selectedCodes.filter((el) => el.code !== item.code))
                    } else {
                      setSelectedCodes([...selectedCodes, item])
                    }
                  }} checked={selectedCodes.map((el) => el.code).includes(item.code)}/>
                </div>
                <div className='OwnerPage__table__row__background'>
                  {headerColumnTable.slice(0, !ownerData?.owner ? 9 : 7).map((codeColumn, index) => (
                    <div className='DataGridTable__row__column' key={index} style={{ flexBasis: codeColumn.width, height: '40px', position: index == 0 ? 'sticky' : '', left: '5%', zIndex: index == 0 ? 1 : ''}}>
                      {codeColumn?.renderCell ? codeColumn.renderCell({value: item[codeColumn.field], row: item}) : item[codeColumn.field]}
                    </div>  
                  ))}
                </div>
              </div>
            ))}
        </section>}
      </div>
      {/* <div className='OwnerPage__row' style={{flexWrap: 'wrap'}}>
        {tabl.map((item, index) => (
          <div className='OwnerPage__dayCard' key={index}>
            <div className='OwnerPage__dayCard__title' style={{background: item.color}}>{item.name}</div>   
            <div className='OwnerPage__dayCard__dataShow'>
              {item.code.length > 0 &&
              <div className='OwnerPage__dayCard__dataShow__column'>
                <div className='OwnerPage__dayCard__dataShow__column__title'>Code</div>
                {item.code.map((code, i) => (<div key={i}>{code}</div>))}
              </div>}
              {item.order.length > 0 &&
              <div className='OwnerPage__dayCard__dataShow__column'>
                <div className='OwnerPage__dayCard__dataShow__column__title'>Order</div>
                {item.order.map((order, i) => (
                  <div 
                    key={i} 
                    style={{ color: order.active == true ? 'green' : '#252525'}}
                    onClick={() => {window.open(order.link,'_blank')}}
                  >{order.code}</div>))}
              </div>}
              {item.code.length == 0 && item.order.length == 0 && <div className='OwnerPage__dayCard__dataShow__notWork'>Not Work</div>}
            </div>
          </div>
        ))}
      </div> */}

      <Modal open={editOwnerCode!==null} onClose={() => {
        setEditOwnerCode(null)
      }}>
        <Box 
        className='NewOrderPage__mainEditOrder__sendOrder__modal OwnerPage__modal'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          outline: 'none',
          border: '3px solid #5FC56E',
          fontFamily: 'Montserrat',
          fontSize: '17px',
          color: '#5FC56E',
          borderRadius: 6,
          gap: 2,
          p: 4,
          padding: '26px 32px',
        }}>
          {!isLoadingСontactCode && contactCode!==undefined && <>
            <h4>{contactCode?.tradeName} paids data</h4>
            <div className='OwnerPage__modal__data'>
              {[...codeValuePaids].sort((a, b) => new Date(a?.date) - new Date(b?.date)).map((el, i) => 
              <div 
                key={'OwnerPage__modal__data'+i}
                className='OwnerPage__modal__data__item'
              >
                <div className='OwnerPage__modal__data__item__number'>{i+1}</div>
                <div className='OwnerPage__modal__data__item__description'>
                  <div className='OwnerPage__modal__data__item__description__row'>
                    <span>Info: {el?.description}</span>
                  </div>
                  <div className='OwnerPage__modal__data__item__description__row'>
                    <span>Pay type: {el.type}</span>
                    <span>date: {el?.date?.split('T')[0].replace(/-/g, '.')}</span>
                  </div>
                  <div className='OwnerPage__modal__data__item__description__row'>
                    <span><b>{el?.confirm ? 'Confirmed' : 'Not confirmed'}</b></span>
                    <span>paid: {parseFloat(el?.value)?.toFixed(2)} €</span>
                  </div>
                </div>
                <button
                  disabled={el.confirm}
                  style={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    justifyContent: 'center', 
                    border: 'none', 
                    background: '#5FC56E', 
                    cursor: 'pointer',
                    color: 'white',
                    borderRadius: '8px',
                    width: '36px',
                    height: '36px',
                  }}
                  title='Confirm payment'
                  onClick={async () => {
                    const arrayPaids = codeValuePaids.filter((e) => e.date !== el.date)
                    const element = {...el, confirm: true}
                    await updateCodesPaid([{
                      tradeName: editOwnerCode,
                      paid: [...arrayPaids, element]
                    }]).then((res) => {
                      refetchСontactCode()
                      setTimeout(() => {
                        dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                      }, 500)
                    })
                    console.log(refetchСontactCode);
                  }}
                >
                  <ConfirmIcon />
                </button>
              </div>)}
            </div>
            <div className='OwnerPage__modal__bottom'>
              <div>
                Count paids: {contactCode.paid.length}
              </div>
              <div>
                Total paids: {parseFloat(codeValuePaids?.reduce((accum, el) => parseFloat(accum) + parseFloat(el.value), 0))?.toFixed(2)} €
              </div>
            </div>
            <div className='OwnerPage__modal__bottom'>
              <button
                style={{
                  display: 'flex', 
                  alignItems: 'center', 
                  justifyContent: 'center', 
                  border: 'none', 
                  background: '#5FC56E', 
                  cursor: 'pointer',
                  color: 'white',
                  borderRadius: '8px',
                  width: '100%',
                  height: '36px'
                }}
                onClick={() => {setIsSendPaymentModal(true)}}
              >
                Add payment
              </button>
            </div>
          </>}
        </Box> 
      </Modal>

      <Modal open={isSendPaymentModal} onClose={() => {
        setIsSendPaymentModal(false)
      }}>
        <Box 
        className='NewOrderPage__mainEditOrder__sendOrder__modal OwnerPage__modal__addPayment'
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          display: 'flex',
          flexDirection: 'column',
          outline: 'none',
          border: '3px solid #5FC56E',
          fontFamily: 'Montserrat',
          fontSize: '17px',
          color: '#5FC56E',
          borderRadius: 6,
          gap: 2,
          p: 4,
        }}>
          <h4>Add Paiment</h4>
          <div className='OwnerPage__modal__addPayment__form'>
            <p>Inter description payment</p>
            <input 
              type='text'
              value={addPaymentData.description}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  description: event.target.value,
                  date: new Date(),
                })
              }}
              placeholder='Inter description payment'
            />
            <p>Paid sum €</p>
            <input 
              type='number'
              value={addPaymentData.value}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  value: event.target.value,
                  date: new Date(),
                })
              }}
              min={0}
              placeholder='Paid sum'
            />
            <p>Type payment</p>
            <select 
              name="type"
              value={addPaymentData.type}
              onChange={(event) => {
                setAddPaymentData({
                  ...addPaymentData,
                  type: event.target.value,
                  date: new Date(),
                })
              }}
            >
              <option value="Tarjeta">Tarjeta</option>
              <option value="Transferencia inmediata">Transferencia inmediata</option>
              <option value="Efectivo">Efectivo</option>
              <option value="Factura invioice">Factura invioice</option>
            </select>
          </div>
          <div className='OwnerPage__modal__bottom'>
            <button
              style={{
                display: 'flex', 
                alignItems: 'center', 
                justifyContent: 'center', 
                border: 'none', 
                background: '#5FC56E', 
                cursor: 'pointer',
                color: 'white',
                borderRadius: '8px',
                width: '100%',
                height: '36px'
              }}
              onClick={async () => {
                // setCodeValuePaids([...codeValuePaids, addPaymentData])
                console.log(addPaymentData);
                // await 
                await updateCodesPaid([{
                  tradeName: editOwnerCode,
                  paid: [...codeValuePaids, addPaymentData]
                }]).then((res) => {
                  refetchСontactCode()
                  setAddPaymentData({confirm: false, value: 0, description: '', date: new Date()})
                  setTimeout(() => {
                    dispatch(mainApi.util.invalidateTags(['ContactBusiness']))  
                  }, 500)
                })
                setIsSendPaymentModal(false)
              }}
              disabled={(addPaymentData.description == '') || (addPaymentData.value == 0)}
            >
              Send payment
            </button>
          </div>
        </Box>
      </Modal>

      {isOpenLockModal && 
      <LockModal 
        password={'2631'} 
        isCloseModal={true} 
        onUnlock={() => {
          setIsLock(false)
          // console.log('sd');
          setIsEditPaid(true)
        }}
        onWrongPassword={() => {
          setIsOpenLockModal(false)
        }}
      />
      }
    </div>
  )
}

export default OwnerPage