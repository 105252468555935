import React, { useState } from 'react'
import './ModalUI.scss'
import CloseIcon from '../../assets/images/closeElem.svg'

const ModalUI = ({ children, sxBtn={}, sx={}, sxModal={}, sxBackground={}, noBtn=false, onBtnClose=false}) => {
  const [isOpenModal, setIsOpenModal] = useState(noBtn);

  return (<>
    {!noBtn && <button onClick={() => {setIsOpenModal(true)}} style={sxBtn}>
      Add code
    </button>}

    {isOpenModal && <div className='WarningModal' onClick={() => {if (!onBtnClose) {setIsOpenModal(false)}}} style={sxBackground}>
      <div className='WarningModal__content'style={sxModal}>
        {!onBtnClose && <div className='WarningModal__content__close'>
          <img src={CloseIcon} alt="" onClick={() => setIsOpenModal(false)} />
        </div>}
        <div className='WarningModal__content__children' style={sx} onClick={(e) => {e.stopPropagation()}}>
          {children}
        </div>
      </div>
    </div>}
  </>
  )
}

export default ModalUI