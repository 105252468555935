import React, { useEffect, useState } from 'react'
import './NewOrderPage.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import takeTime from '../../../constants/takeTime'
import { PhoneInput } from "react-international-phone";
import CardV1 from '../assets/images/CardV1.svg'
import CardV2 from '../assets/images/CardV2.svg'
import "react-international-phone/style.css";
import {
  Checkbox,
} from '@mui/material'
import { mainApi } from '../../../store/services/mainApi'
import { setItemsCart } from '../../../store/slices/allItemsCart'
import { setIdLastOrder, setIsLogin, setPaymentMetod, setWarnModal } from '../../../store/slices/contextUI'
import {Modal, Box, TextField, Button} from '@mui/material'
import CircularProgress from '@mui/material/CircularProgress';
import unitEqual from '../../../constants/unitEqual'
import axios from 'axios'
import useFindItemByUnit from '../../../hooks/useFindItemByUnit'
import { setUserAcc } from '../../../store/slices/accountData'
import { PhoneNumberUtil } from 'google-libphonenumber';
import useSendLog from '../../../hooks/useSendLog'
import orderDataConst from '../../../constants/OrderData'
import TotalCart from '../TotalCart/TotalCart'
import useCalculateCart from '../../../hooks/useCalculateCart'
import useUpdatePrice from '../../../hooks/useUpdatePrice'
import SendOrderAnim from '../../../lotties/sendorder.json'
import Lottie from 'lottie-react'


import DatePicker from 'react-datepicker';
import { format, getDay } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};



const NewOrderPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const calcCart = useCalculateCart()
  const { data: allProductsData, isLoading } = mainApi.useFindAllWordsQuery({
    pollingInterval: 50000,
  });
  // const { data: сontactData, isLoading: isContactData  } = mainApi.useGetContactsTradeNamesAdminQuery();

  const tradeNameParams = useParams().tradeName 
  const platformParams = useParams().platform

  const {
    data: contact,
    isLoading: isContactLoading,
    isError,
  } = mainApi.useGetBuisnessContactByTradeNameQuery(tradeNameParams, {
    skip: !tradeNameParams,
  })

  const [isSendOrderModal, setIsSendOrderModal] = useState(false)
  const [handleOrder, setHandleOrder] = useState(false)

  useEffect(() => {
    if (isSendOrderModal) {
      setTimeout(() => {
        if (isSendOrderModal) {  
          if (tradeNameParams !== undefined) {
            navigate(`/orderComplited/${tradeNameParams}`)
          } else {
            navigate('/orderComplited')
          }
          setIsSendOrderModal(false)
        }
      }, [5000])
    }
  }, [isSendOrderModal, handleOrder])
  const allCart = useSelector((state) => state.allItemsCart.allItemsCart)
  const allItemsUpdatedCart = useUpdatePrice(allCart, allProductsData, isLoading)
  const date = new Date();

  // Блокировка выбора среды (3) и воскресенья (0)
  const isDayDisabled = (date) => {
    const day = getDay(date);
    return day === 3 || day === 0;
  };

  // const formattedDate = () => {
  //   const data = new Date();
  //   const day = new Date();
  //   if (isDayDisabled(data)) {
  //     console.log(new Date(data.setDate(data.getDate()+1)).toISOString()?.split('T')[0]);
  //     return new Date(data.setDate(data.getDate()+1)).toISOString()?.split('T')[0]
  //   } else {
  //     console.log('dd'); 
  //     if (!(day.getHours()>0&&day.getHours()<9)) {
  //       if (isDayDisabled(new Date(data.setDate(data.getDate()+1)).toISOString()?.split('T')[0])) {
  //         return new Date(day.setDate(day.getDate()+2)).toISOString()?.split('T')[0]
  //       }
  //       else {
  //         return new Date(day.setDate(day.getDate()+1)).toISOString()?.split('T')[0]
  //       }
  //     } else {
  //       return new Date().toISOString()?.split('T')[0]
  //     }
  //   }
  // }

      console.log('+1');
  const calculateDeliveryDate  = () => {
    const date = new Date();
    let deliveryDate = new Date(date);

    // Шаг 1: Проверка исходного дня
    const initialDay = deliveryDate.getDay();
    if (initialDay === 0 || initialDay === 3) {
      deliveryDate.setDate(date.getDate() + 1);
    } else {
      // Шаг 2: Проверка времени для других дней
      const hours = date.getHours();
      if (hours >= 9) {
        deliveryDate.setDate(date.getDate() + 1);
      }
    }

    // Шаг 3: Проверка результата на запрещенные дни
    const resultDay = deliveryDate.getDay();
    if (resultDay === 0 || resultDay === 3) {
      deliveryDate.setDate(deliveryDate.getDate() + 1);
      
    }

    // Сбрасываем время для точного сравнения дат
    deliveryDate.setHours(0, 0, 0, 0);
    return deliveryDate;
  };
  const formattedDate = calculateDeliveryDate()
  const formattedDateNY25 = date.getDate > 30 ? formattedDate : new Date(date.setDate(30)).toISOString()?.split('T')[0] 


  const [replacedCart, setReplacedCart] = useState([])
  useEffect(() => {
    if (Array.isArray(allCart)) {
      setReplacedCart(allCart?.map((el) => {
        if (unitEqual.find((item) => item.sku === el.sku)) {
          const itemFind = useFindItemByUnit(el, el.currentUnit)
          // console.log(itemFind);
          return {
            ...el,
            quantityOrder: parseFloat(el.quantity) * itemFind,
            quantity: parseFloat(el.quantity) * itemFind,
            currentUnit: 'kg',
            currientRequest: `${el.quantity} ${el.currentUnit}`,
            ourPrice: el.ourPrice,
            ourPriceDP: el.ourPrice,
            ourPriceSDP: el.ourPrice,
            ourPriceCP: el.ourPrice,
          }
        } else {
          return {...el,
            currientRequest: `${el.quantity} ${el.currentUnit}`,
            ourPrice: el.ourPrice,
            ourPriceDP: el.ourPrice,
            ourPriceSDP: el.ourPrice,
            ourPriceCP: el.ourPrice,}
        }
      }))
    }
  }, [allCart])

  useEffect(() => {
    console.log(replacedCart);
  }, [replacedCart])

  const [createOrder, { data: result, isSuccess }] = mainApi.useCreateNewOrderBrowserMutation()
  const [createUserPhone, { data: resultPhone, isSuccess: isSuccessPhone }] = mainApi.useAddClientPersonalPhonesMutation()
  const [updateOrder, { data: resultUpdateOrder, isSuccess: isSuccessUpdateOrder }] = mainApi.useUpdateOrderItemsAdminMutation()

  const sendOrderLog = useSendLog('Send Order', { page: 'NewOrderPage' });
  const sendOrderLogAgain = useSendLog('Send Order Again', { 
  });

  const cardData = [{img: CardV1, number: '**1234', name: 'Card 1'}, {img: CardV2, number: '**5678', name: 'Card 2'}]
  
  
  const [editTab, setEditTab] = useState(1)
  const [showPayBox, setShowPayBox] = useState(false)
  const [cardActive, setCardActive] = useState(cardData[0])

  const localUserData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
  const [nameUser, setNameUser] = useState(localUserData?.name ? localUserData.name : '')
  const [description, setDescription] = useState(localUserData?.tradeNameAdress ? localUserData.tradeNameAdress : '')
  const [number, setNumber] = useState(localUserData?.phone ? localUserData.phone : '')
  const [tradeName, setTradeName] = useState(localUserData?.tradeNameCode ? localUserData.tradeNameCode : '')
  const isValid = isPhoneValid(number);
  
  
  const method = useSelector((state) => state.contextUI.paymentMetod) 
  useEffect(() => {
    setOrderData((prev) => ({
      ...prev, deliveryDate: formattedDate
    }))
  }, [])

  const changeDelDate = (e, type) => {
    switch (type) {
      case 'day': {
        setEDate(e)
        break}
      case 'time': {
        setETime(e)
        break}
    }
  }
  const [eTime, setETime] = useState('PLM ☀️')
  const [eDate, setEDate] = useState(formattedDate)


  useEffect(() => {
    if (isDayDisabled(formattedDate)) {
      alert('Invalid date')
    }
  }, [eDate])
  
  const formatDate = (date) => {
    const d = new Date(date);
    const day = d.getDate().toString().padStart(2, '0');
    const month = (d.getMonth() + 1).toString().padStart(2, '0');
    const year = d.getFullYear();
    return `${day}.${month}.${year}`;
  };
  
  const [orderData, setOrderData] = useState(orderDataConst)

  const isLogined = useSelector((state) => state.contextUI.isLogin)
  const isWarn = useSelector((state) => state.contextUI.uiModal.warnModal)
  const userData = useSelector(state => state.accountData.userAcc)
  

  useEffect(() => {
    setOrderData((prev) => ({
      ...prev,
      paymentType: method,
    }))
  }, [method])
 

  useEffect(() => {
    if (tradeNameParams !== undefined) {
      const tradeId = async () => { 
        const responseLogin = await axios.get(`https://tpomobi.shop/getBuisnessContactByTradeName?tradeName=${tradeNameParams}`)
        setDescription(tradeNameParams == 'NY25' ? 'Ваш адрес' : responseLogin.data?.name)
        setTradeName(responseLogin.data?.tradeName)
      }
      tradeId()
    } 
  }, [tradeNameParams])
  

  const [lastOrderTime, setLastOrderTime] = useState(null);
  const [orderSent, setOrderSent] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (orderSent && Date.now() - lastOrderTime < 60000) {
        sendOrderLogAgain();
      }
    }, 60000); // 1 minute

    return () => clearTimeout(timeoutId);
  }, [lastOrderTime, orderSent]);
  const [dataBussnes, setDataBussnes] = useState(null)

  const [updateCartContact, { data: resultCart, isSuccess: isSuccessCart }] = mainApi.useUpdateCurrentCartContactMutation()


  const isNY25 = useParams().tradeName == 'NY25' ? true : false

  const handleSendOrder = async () => {
    setHandleOrder(true)
    let informData =  {
      phone: number.replace('+',''),
      password: 'any',
    };

    let cart = replacedCart?.map((el) => 
      ({...el,
        marketPrice: el.SPOPrice ? el.SPOPrice : el.marketPrice,
        marketPriceDP: el.SPOPrice ? el.SPOPrice : el.marketPriceDP,
        marketPriceSDP: el.SPOPrice ? el.SPOPrice : el.marketPriceSDP,
        marketPriceCP: el.SPOPrice ? el.SPOPrice : el.marketPriceCP,
      }))
    await createOrder({ data: {...orderData,
      deliveryDate: formatDate(eDate)+', '+eTime,
      deliveryTime: eTime,
      address: description !== '' ? description : '',
      items: cart,
      description: isNY25 ? date.getDate > 30 ? orderData.description + '(Confirm Date)' : orderData.description  : orderData.description,
      paymentType: tradeNameParams !== undefined ? 'Credit Line' : method ,
      tradeName: tradeNameParams !== '' ? tradeNameParams : userData.tradeName !== '' ? userData.tradeName : 'Not user TradeName',
      userAcc: {
        ...userData,
        name: nameUser !== '' ? platformParams !== undefined ? `${nameUser} (${platformParams})`: isNY25 ? nameUser + ' (CCN)' : nameUser : '',
        phone: number !== '' ? number.replace('+', '') : '',
        tradeName: tradeNameParams !== '' ? tradeNameParams : userData.tradeName !== '' ? userData.tradeName : '',
      }
    } }).then(async (res) => {
      sendOrderLog()
      setOrderSent(true);
      setLastOrderTime(Date.now());

      dispatch(setIdLastOrder(res.data.insertedId))
      dispatch(setWarnModal(false))
      const localStorageData = localStorage.getItem('userData') ? JSON.parse(localStorage.getItem('userData')) : null
      localStorage.setItem('userData', JSON.stringify({
        ...localStorageData,
        name: nameUser,
        phone: number.replace('+',''),
        tradeNameAdress: description,
        tradeNameCode: tradeName,
      }))
       
      const responseLogin = axios.post('https://tpomobi.shop/acceptLoginAcc', {data: informData})
      // console.log(responseLogin.data)
      
      dispatch(setItemsCart([]))
      if (number) {
      await updateCartContact({ data: {
        phone: number.replace('+', ''),
        currentCart: [],
      }})
    }
      if (responseLogin.data !== '') {
        createUserPhone({ phone: number.replace('+','') }).then((res) => {
          console.log(res.data)
        })
      }
      dispatch(setUserAcc(responseLogin.data))
      setDataBussnes(responseLogin.data)
      dispatch(setIsLogin(responseLogin.data !== '' ? true : false))
      setTimeout(() => {
        setHandleOrder(false)
      }, 8000)
      setIsSendOrderModal(true)
    }).catch((err) => {
      alert('Try again!!!')
    })
  }
  
  const handleSendOrderAcc = async () => {
    setIsSendOrderModal(true)
    setHandleOrder(true)
    let cart = replacedCart?.map((el) => ({...el, marketPriceCP: el?.spoPrice ? el.spoPrice : el.marketPriceCP, marketPriceDP: el?.spoPrice ? el.spoPrice : el.marketPriceCP, marketPriceSDP: el?.spoPrice ? el.spoPrice : el.marketPriceCP, marketPrice: el?.spoPrice ? el.spoPrice : el.marketPriceCP}))
    await createOrder({ data: {...orderData,
      deliveryDate: formatDate(eDate)+', '+eTime,
      deliveryTime: eTime,
      address: description !== '' ? description : '',
      items: cart,
      paymentType: tradeNameParams !== undefined ? 'Credit Line' : method ,
      tradeName: tradeNameParams !== '' ? tradeNameParams : userData.tradeName !== '' ? userData.tradeName : 'Not user TradeName',
      userAcc: userData
    } }).then(async (res) => {
      sendOrderLog()
      setOrderSent(true);
      setLastOrderTime(Date.now());

      dispatch(setIdLastOrder(res.data.insertedId))
      dispatch(setItemsCart([]))
      if (number) {
        await updateCartContact({ data: {
          phone: number.replace('+', ''),
          currentCart: [],
        }})
      }
      dispatch(setWarnModal(false))
      setTimeout(() => {
        setHandleOrder(false)
      }, 5000)
      if (tradeNameParams !== undefined) {
        if (platformParams !== undefined) {navigate(`/orderComplited/${platformParams}/${tradeNameParams}`)} else {
          navigate(`/orderComplited/${tradeNameParams}`)
        }
      } else {
        navigate('/orderComplited')
      }
    }).catch((err) => {
      alert('Try again!!!')
    })
  }

  const [emptyCartWarn, setEmptyCartWarn] = useState(false)
  
  const userReachedPayment = useSendLog('User didn\'t complited payment', { 
  });

  useEffect(() => {
    const userReached = setTimeout(async () => {
      userReachedPayment()
    }, 3000);
    return () => {
      clearTimeout(userReached)
    }
  }, [])

  const [startDate, setStartDate] = useState(tradeNameParams == 'NY25' ? formattedDateNY25 : formattedDate);

  function checkDate(inputDate) {
    const today = new Date();
    
    // Сравниваем даты без времени (год, месяц, день)
    const currentDate = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    const targetDate = new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());

    // Если дата раньше текущей
    if (targetDate < currentDate) {
      return false;
    }

    // Если дата совпадает с текущей, проверяем часы
    if (targetDate.getTime() === currentDate.getTime()) {
      const hours = inputDate.getHours();
      // Возвращаем true, если часы между 0 и 9 (включительно)
      return !(hours >= 0 && hours < 9);
    }

    // Если дата в будущем
    return true;
  }


  console.log(formattedDate);
  // Кастомизация отображения дней
  const renderDayContents = (day, date) => {
    const dayOfWeek = getDay(date);
    const isDisabled = isDayDisabled(date);
    return (
      <div 
        className={`day-wrapper ${!checkDate(date) && !isDisabled ? 'disabled-day' : ''}`}
        title={!isDisabled ? '' : 'Recuerda que NO HAY reparto los MIÉRCOLES y los DOMINGOS'}  
        onClick={() => {
          if (isDisabled) {
            alert('Recuerda que NO HAY reparto los MIÉRCOLES y los DOMINGOS')
          }
        }}
      >
        {day}
      </div>
    );
  };

  return (<>
    <div className='NewOrderPage'>
      {/* {isAlertModal && 
        <AlertModal closeFunc={setIsAlertModal}>
          Hola buenas tardes!
          <br/>
          <br/>
          Martes 24/09, es festivo, *no vamos a tener servicio* 
          <br/>
          <br/>
          Pero el miércoles, solo está semana, vamos a entregar la mercancía 
          <br/>
          <br/>
          Por favor, hagan sus comandas para mañana completas, para llegar hasta el martes incluido.
          <br/>
          <br/>
          Gracias
        </AlertModal>} */}
      <div className='NewOrderPage__container'>
        <div className='NewOrderPage__mainEditOrder'>
          <div className='NewOrderPage__mainEditOrder__item'>
            {editTab === 0 && 
            <div className='NewOrderPage__mainEditOrder__item__body'>
              <div className='NewOrderPage__mainEditOrder__item__body__inputRow'>
                <input 
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__input'
                  type="text" 
                  value={orderData.userAcc.name}
                  onChange={(e) => {
                    setOrderData((prev) => ({...prev, userAcc: {...prev.userAcc, name: e.target.value}}))
                  }}
                  placeholder='first name*'
                />
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__inputRow'>
                <input 
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__input'
                  type="text" 
                  value={orderData.address}
                  onChange={(e) => {setOrderData((prev) => ({...prev, address: e.target.value}))}}
                  placeholder='Address*'
                />
                <PhoneInput
                  className='NewOrderPage__mainEditOrder__item__body__inputRow__input__phoneInput'
                  defaultCountry="es"
                  value={orderData.userAcc.phone}
                  onChange={(e) => {setOrderData((prev) => ({...prev, userAcc: {...prev.userAcc, phone: e}}))}}
                />
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__inputRow'>
              </div>
              
            </div>}
          </div>
          <div className='NewOrderPage__mainEditOrder__item'>
            {tradeNameParams == undefined && <div 
              className='NewOrderPage__mainEditOrder__item__header'
              onClick={() => setEditTab(1)}
              style={{color:  editTab !== 1 ? '#b0b0b0' : '#212b36',
              width: editTab === 1 ? '100%' : '98%',
            }}
            >
              {/* 1. */}
               Payment
              {/* <img 
                src={ArrowUp} 
                alt="" 
                style={{transform: editTab === 1 ? 'rotate(180deg)' : '',
                filter:  editTab !== 1 ? 'brightness(0.5)' : '',
                }}/> */}
            </div>}
            {editTab === 1 && 
            <div className='NewOrderPage__mainEditOrder__item__body'>
              {/* {tradeNameParams === undefined && <div className='NewOrderPage__mainEditOrder__item__body__delivery'>
                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E' } }}
                    checked={orderData.paymentType == 'Pick Up'}
                    onClick={() => {
                      setOrderData((prev) => ({...prev, paymentType: 'Pick Up'}))
                      dispatch(setPaymentMetod('Pick Up'));
                      localStorage.setItem('paymentMethod', 'Pick Up');
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Pick Up <span>Mercabarna</span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {calcCart.pickUpPrice?.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You save</div>
                        <span>
                          {calcCart.pickUpPrice?.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        -{calcCart.percentageOrder?.pickUp}% <span style={{fontSize: '10px'}}>(coming soon)</span>
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E' } }}
                    checked={orderData.paymentType == 'Pay Online'}
                    onClick={() => {
                      setOrderData((prev) => ({...prev, paymentType: 'Pay Online'}))
                      dispatch(setPaymentMetod('Pay Online'));
                      localStorage.setItem('paymentMethod', 'Pay Online');
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Pay Online
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {calcCart.payOnlinePrice?.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You save</div>
                        <span>
                          {calcCart.payOnlinePrice?.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        -{calcCart.percentageOrder?.payOnline}%
                      </div>
                    </div>
                  </div>
                </div>
                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payment'>
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payment__item'>
                    <img src={cardActive.img} alt="" />
                    <div>{cardActive.number}</div>
                    <div 
                      onClick={() => setShowPayBox(!showPayBox)}
                      className='NewOrderPage__mainEditOrder__item__body__delivery__item__payment__item__change'
                    >
                      Change
                    </div>
                  </div>
                  {showPayBox &&
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payBox'>
                      {cardData.map((item, index) => (
                        <div 
                          className='NewOrderPage__mainEditOrder__item__body__delivery__item__payBox__item'
                          onClick={() => setCardActive(item)}
                          key={item.name}
                          style={{background: cardActive.name === item.name ? '#5FC56E' : '#ffffff', color: cardActive.name === item.name ? '#ffffff' : '#5FC56E'}}
                        >
                          <img src={item.img} alt="" />
                          <div>{item.number}</div>
                          <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__payBox__item__name'>{item.name}</div>
                        </div>
                      ))}
                    </div>
                  }
                </div>

                <div className='NewOrderPage__mainEditOrder__item__body__delivery__item'>
                  <Checkbox
                    className='NewOrderPage__mainEditOrder__item__body__delivery__item__checkbox'
                    sx={{ '& .MuiSvgIcon-root': { fontSize: 40, color: '#5FC56E' } }}
                    checked={orderData.paymentType == 'Credit Line'}
                    onClick={() => {
                      setOrderData((prev) => ({...prev, paymentType: 'Credit Line'}))
                      dispatch(setPaymentMetod('Credit Line'));
                      localStorage.setItem('paymentMethod', 'Credit Line');
                    }}
                  />
                  <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data'>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__title'>
                        Credit Line
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__price'>
                        {calcCart.CreditLinePrice?.total.toFixed(2)}€
                      </div>
                    </div>
                    <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row'>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__save'>
                        <div>You not save</div>
                        <span>
                          +{calcCart.CreditLinePrice?.save.toFixed(2)}€
                        </span>
                      </div>
                      <div className='NewOrderPage__mainEditOrder__item__body__delivery__item__data__row__percent'>
                        +{calcCart?.percentageOrder?.creditLine}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>} */}
              <div className='NewOrderPage__mainEditOrder__item__body__timetitle'>
                Elije su horario de la entrega
                <div className='NewOrderPage__mainEditOrder__item__body__timetitle__box'>
                  {takeTime.map((el) => 
                  <div
                    key={el.title}
                    className='NewOrderPage__mainEditOrder__item__body__timetitle__box__item'
                    onClick={() => {changeDelDate(el.title, 'time')}}
                    style={{background: eTime === el.title ? '#5FC56E' : ''}}
                  >
                    {el.time}
                  </div>)}
                </div>
                <div><br />Si se necesita una hora especial, apuntala en los comentarios más adelante por favor</div>
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__timetitle'>
                <b>Confirma o cambia la fecha 📆</b>
                <div className='NewOrderPage__mainEditOrder__item__body__timetitle__box'>
                  <DatePicker
                    className='NewOrderPage__mainEditOrder__item__body__timetitle__box__date'
                    showIcon
                    selected={eDate}
                    onChange={(date) => {setEDate(date.toISOString()?.split('T')[0]); console.log(date.toISOString()?.split('T')[0]);}}
                    filterDate={date => !isDayDisabled(date) && checkDate(date)}
                    renderDayContents={renderDayContents}
                    dateFormat="dd.MM.yyyy"
                    placeholderText="Выберите дату"
                    // dayClassName={(date) =>
                    //   {
                    //     // getDate(date) < Math.random() * 31 ? "random" : undefined
                    //     const dateNow = new Date();
                    //     const formattedDate = !(date.getHours()>0&&date.getHours()<9) ? new Date(date.setDate(date.getDate() + 1)).toISOString()?.split('T')[0] : new Date().toISOString()?.split('T')[0];
                    //     return formattedDate ? "disabled-day" : undefined
                    //   }
                    // }
                  />
                  {/* <input 
                    style={{
                      width: '100%', 
                      border: '2px solid #5FC56E', 
                      borderRadius: '10px', 
                      padding: '10px', 
                      resize: 'none', 
                      outline: 'none',
                      font: 'Montserrat',
                      fontSize: '18px'
                    }}
                    type="date"
                    min={tradeNameParams == 'NY25' ? formattedDateNY25 : formattedDate} 
                    max={tradeNameParams == 'NY25' && formattedDateNY25}
                    value={tradeNameParams == 'NY25' ? formattedDateNY25 : eDate}   
                    onChange={(e) => {setEDate(e.target.value); console.log(e.target.value);}}
                    /> */}
                </div>
                    <br />
                <span>Recuerda que <b>no hay reparto los Miércoles y los Domingos</b></span>
              </div>
              <div className='NewOrderPage__mainEditOrder__item__body__timetitle'>
                Dejar el comentario y los detalles
                <div className='NewOrderPage__mainEditOrder__item__body__timetitle__box'>
                  <textarea 
                    style={{
                      width: '100%', 
                      border: '2px solid #5FC56E', 
                      borderRadius: '10px', 
                      padding: '10px', 
                      resize: 'none', 
                      outline: 'none',
                      font: 'Montserrat',
                      fontSize: '18px'
                    }}
                    value={orderData.description}
                    onChange={(e) => {setOrderData((prev) => ({...prev, description: e.target.value}))}}
                  />
                </div>
              </div>

            </div>}
          </div>
          {/* <div className='NewOrderPage__mainEditOrder__item'>
            <div 
              className='NewOrderPage__mainEditOrder__item__header'
              onClick={() => setEditTab(2)}  
              style={{color:  editTab !== 2 ? '#b0b0b0' : '#212b36',
              width: editTab === 2 ? '100%' : '98%',
            }}
            >
              3. Deduct bonus points
              <img 
                src={ArrowUp} 
                alt="" 
                style={{transform: editTab === 2 ? 'rotate(180deg)' : '',
                filter:  editTab !== 2 ? 'brightness(0.5)' : '',
                }}/>
            </div>
            {editTab === 2 && 
            <div className='NewOrderPage__mainEditOrder__item__body'>
              
            </div>} 
          </div> */}
        </div>
      </div>
      

      <TotalCart minOrderPrice={contact?.minOrderPrice} />
    </div>
    <button
      className='NewOrderPage__mainEditOrder__sendOrder'
      disabled={calcCart.totalPrice.toFixed(2) < (contact?.minOrderPrice || 25)}
      onClick={() => {
        // if (findUserAccept) {
          if (allCart.length > 0) {
            if (isLogined & (tradeNameParams == undefined)) 
              {!handleOrder && 
              allCart.length > 0 && 
              userData.phone !== '' && 
              userData.name !== '' && 
              userData.description !== '' &&
              handleSendOrderAcc()} 
            else {
              dispatch(setWarnModal(true))
            }
          } else {
            console.log(allCart);
            setEmptyCartWarn(true)
          }
        // } else {
        //   alert('User tradeName not found')
        // } 
      }} 
    >
      Enviar el pedido
    </button>
    {(calcCart.totalPrice.toFixed(2) < (contact?.minOrderPrice || 25) || tradeNameParams ==undefined) &&
    <div className='NewOrderPage__mainEditOrder__sendOrder__warn'>
      El importe mínimo tiene que ser {contact?.minOrderPrice || 25} € <br></br>
      Su pedido es menos de {((contact?.minOrderPrice || 25) - calcCart.totalPrice.toFixed(2)).toFixed(2)}€ 
    </div>}
    {isContactLoading && 
    <div className='NewOrderPage__mainEditOrder__sendOrder__warn'>
      Incorect link
      <br />
      Check you code in link
    </div>
    }
    {/* calcCart.totalPrice.toFixed(2) */}
    {/* {isWarn &&
    <WarningModal timeout={2000}>
        Great!!!
        <br/>
        You need to log in or register an account!
    </WarningModal> */}
    <Modal open={isSendOrderModal} onClose={() => {
      }}>
      <Box 
      className='NewOrderPage__mainEditOrder__sendOrder__modal'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        border: '3px solid #5FC56E',
        fontFamily: 'Montserrat',
        fontSize: '17px',
        color: '#5FC56E',
        borderRadius: 6,
        gap: 2,
        p: 4,
      }}>
        <Lottie
          animationData={SendOrderAnim}
          autoplay={true}
          loop={false}
        />
      </Box> 
    </Modal>

    <Modal open={isWarn} onClose={() => {
        if (!handleOrder) {dispatch(setWarnModal(false))}
      }}>
      <Box 
      className='NewOrderPage__mainEditOrder__sendOrder__modal'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        border: '3px solid #5FC56E',
        fontFamily: 'Montserrat',
        fontSize: '17px',
        color: '#5FC56E',
        borderRadius: 6,
        gap: 2,
        p: 4,
      }}>
        <b style={{marginBottom: '10px'}}>Genial👍<br/>
        Ahora dinos tus  detalles para acabar el pedido:</b>
        <TextField
          type="text"
          label="Tu nombre"     
          value={nameUser}
          onChange={(e) => setNameUser(e.target.value)} 
        />
        <div style={{display: 'flex', gap: '10px'}}>
          <TextField
            type="text"
            sx={{flex: 2}}
            label="El nombre del local"
            value={description}
            disabled={tradeNameParams == 'NY25' ? false : !(tradeNameParams === undefined || dataBussnes !== null)}
            onChange={(e) => setDescription(e.target.value)}
          />
          {tradeNameParams !== 'NY25' &&
          <TextField
            type="text" 
            sx={{flex: 1}}
            label="Tu código"
            value={tradeName}
            disabled={!(tradeNameParams === undefined || dataBussnes !== null)}
            onChange={(e) => setTradeName(e.target.value)}
          />}
        </div>
        <PhoneInput
          className='NewOrderPage__phoneInput'
          defaultCountry="es"
          value={number}
          onChange={(e) => {setNumber(e)}}
        />
        {!handleOrder && allCart.length > 0  ? 
        <Button
          variant='contained'
          disabled={!isValid && !handleOrder}
          sx={{
            width: '100%',
            fontFamily: 'Montserrat',
            backgroundColor: '#5FC56E',
            '&:hover': {
              backgroundColor: '#5FC56E',
            },
          }}
          onClick={() => {
              // if (findUserAccept) {
                if (nameUser !== '' && number.length > 5 && description !== '') {
                  handleSendOrder()
                } else {
                  alert('Rellene todos los campos')
                }
              // } 
            }}
        >
          {!isValid ? 'Introduzca el número correcto' : 'Confirmar'}
        </Button>: <CircularProgress/>} 
      </Box> 
    </Modal>

    <Modal open={emptyCartWarn} onClose={() => setEmptyCartWarn(false)}>
      <Box 
      className='NewOrderPage__mainEditOrder__sendOrder__modal'
      sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        display: 'flex',
        flexDirection: 'column',
        outline: 'none',
        border: '3px solid #5FC56E',
        fontFamily: 'Montserrat',
        fontSize: '17px',
        color: '#5FC56E',
        borderRadius: 6,
        gap: 2,
        p: 4,
      }}>
        <b style={{marginBottom: '10px'}}>Atención❗️<br/>
        No es posible enviar el pedido sin ningún artículo elegido! <br />
        <br />
        Por favor elige los productos necesarios
        </b>
        
        {!handleOrder ? <Button
          variant='contained'
          sx={{
            width: '100%',
            fontFamily: 'Montserrat',
            backgroundColor: '#5FC56E',
            '&:hover': {
              backgroundColor: '#5FC56E',
            },
          }}
          onClick={() => {
            setEmptyCartWarn(false)
            if (tradeNameParams !== undefined) {
              if (platformParams !== undefined) {navigate(`/category/${platformParams}/${tradeNameParams}`)} else {
                navigate(`/category/${tradeNameParams}`)
              }
            } else {
              navigate('/home')
            }
          }}
        >
          Confirmar
        </Button>: <CircularProgress/>} 
      </Box> 
    </Modal>
    {/* } */}
    </>
  )
}

export default NewOrderPage