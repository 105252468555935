import React, { useState } from 'react'
import './LockModal.scss'
import ModalUI from './../ModalUI/ModalUI';
import { useNavigate } from 'react-router';

const LockModal = ({
    children, 
    password='2810',
    isCloseModal=true,
    onUnlock=() => {},
    onWrongPassword=() => {}
  }) => {
  const navigate = useNavigate()
  
  const [isClose, setIsClose] = useState(isCloseModal)
  const [passwordValue, setPasswordValue] = useState('')

  const enterPressed = (event) => {
    var code = event.keyCode || event.which;
    if(code === 13) { //13 is the enter keycode
      if (passwordValue === password) {
        setIsClose(false)
        onUnlock()
      } else {
        alert('Wrong password')
        navigate()
        onWrongPassword()
      }
    } 
  }

  return (
    !isClose ?
    <></> :
    <ModalUI
      onBtnClose
      noBtn
      sxBackground={{backgroundColor: '#e8e8e8'}}
      sxBtn={{
        outline: 'none',
        border: 'none',
        width: '100%',
        height: '30px',
        background: '#5fc56e',
        color: '#fff',
        fontSize: '12px',
        fontWeight: '700',
        textAlign: 'center',
        borderRadius: '8px',
        marginTop: 'auto',
        marginBottom: '5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      sxModal={{
        borderRadius: '18px',
        border: '1px solid #5fc56e',
        paddingBottom: '5px',
        height: isClose ? '200px' : 'auto',
        minHeight: isClose ? '130px' : '300px',
        maxHeight: isClose ? '150px' : '720px',
      }}
      sx={{height: '100%', gap: '5px', display: 'flex', flexDirection: 'column'}}
    >
      <div className='LockModal'>
        <input 
          type="number"
          min="0"
          max="9999"
          value={passwordValue}
          onChange={(e) => setPasswordValue(e.target.value)}
          placeholder='0000'
          onKeyUp={(e) => enterPressed(e)}
        />
        <button onClick={() => {
          if (passwordValue === password) {
            setIsClose(false)
            onUnlock()
          } else {
            alert('Wrong password')
            navigate()
            onWrongPassword()
          }}}>Unlock</button>
      </div>
    </ModalUI>
  )
}

export default LockModal