import React, { useMemo } from 'react'
import { Page, Text, View, Document, StyleSheet, Image, Font } from '@react-pdf/renderer'
import logo from '../../components/assets/images/TPOLogoNew.png'
import { useSelector } from 'react-redux'


const styles = StyleSheet.create({
  document: {
    display: 'block',
  },
  page: {
    flexDirection: 'column',
  },
  section: {
    margin: 10,
    padding: 10,
  },
  view: {
    border: '1 solid grey',
  },
  tableHeader: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    margin: '0 20',
    border: '1 solid grey',
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: '1 solid grey',
  },
  tableHeaderСell: {
    flex: 1,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  tableHeaderСellLong: {
    flex: 3,
    padding: 5,
    fontWeight: 'bold',
    fontSize: 8,
    fontFamily: 'Helvetica',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
    margin: '0 20',
    borderBottom: '1 solid grey',
  },
  cell: {
    flex: 1,
    padding: 5,
    fontSize: 11,
    fontFamily: 'Helvetica',
    borderRight: '1 solid grey',
  },
  cellLong: {
    flex: 3,
    padding: 5,
    fontSize: 11,
    fontFamily: 'Helvetica',
    borderRight: '1 solid grey',
    borderLeft: '1 solid grey',
    display: 'flex',
    flexDirection: 'column',
  },
  textTitle: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '400',
  },
  flexView: {
    display: 'flex',
    flexDirection: 'row',
    gap: 10,
    margin: 20,
    justifyContent: 'flex-end',
  },
  textTitleTotal: {
    fontFamily: 'Helvetica',
    fontSize: 12,
    fontWeight: '400',
    textAlign: 'right',
  },
  textTitleBold: {
    fontFamily: 'Helvetica-Bold',
    fontSize: 12,
  },
  textThrough: {
    textDecoration: 'line-through'
  },
})


const PDFOrders = ({ contact, items }) => {
  console.log(items, 'items')

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            marginTop: 20,
            gap: 10,
            width: '50%',
            alignItems: 'center',
            height: '31px',
          }}
        >
          <Image
            source={logo}
            style={{
              height: 31,
              marginLeft: 20,
            }}
          />
          <Text
            style={{
              textAlign: 'center',
              color: '#176318',
              fontFamily: 'Helvetica',
              fontSize: 14,
              fontWeight: '800',
              letterSpacing: 1.4,
              width: '163px',
            }}
          >
            Tu Producto Online
          </Text>
        </View>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            margin: 20,
          }}
        >
          <View>
            <Text style={styles.textTitle}>Items count: {items.length}</Text>
            <Text style={styles.textTitle}>Cliente: {contact?.name || 'N/A'}({ contact?.tradeName || ''})</Text>
            <Text style={styles.textTitle}>Fecha: {new Date().toLocaleString()}</Text>
          </View>
        </View>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <Text style={styles.tableHeaderСell}>Sku</Text>
            <Text style={styles.tableHeaderСellLong}>Product</Text>
            <Text style={styles.tableHeaderСell}>Price Catalogo</Text>
            <Text style={styles.tableHeaderСell}>Price Oferta</Text>
            <Text style={styles.tableHeaderСell}>Los precios sin IVA</Text>
          </View>
          {!items ? (
            <Text>some</Text>
          ) : (
            items.map((item) => (
              <View wrap={false} key={item.conceptio} style={styles.row}>
                <View style={styles.cell}>
                  <Text style={{ fontSize: 8, fontFamily: 'Helvetica', fontWeight: 'bold' }}>{item?.sku}</Text>
                </View>
                <View style={styles.cellLong}>
                  <Text style={{ fontSize: 11, fontFamily: 'Helvetica' }}>{item?.name}</Text>
                </View>
                <View style={styles.cell}>
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <Text>{(item?.marketPriceCP)?.toFixed(2)}€</Text>
                  </View> 
                </View>
                <View style={styles.cell}>{item?.spoPrice && 
                  <View style={{display: 'flex', flexDirection: 'row'}}>
                    <Text>{(item?.spoPrice/ (1+item.IVACP/100)).toFixed(2)}€</Text>
                  </View> 
                }</View>
                <View style={styles.cell}>{
                item?.marketPriceCP ?
                  <Text>{(item?.marketPriceCP/ (1+item.IVACP/100)).toFixed(2)}</Text>
                : <Text>N/A</Text>}€</View>
              </View>
            ))
          )}
        </View>
        {/* <View wrap={false} style={styles.flexView}>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>BASE IMPONIBLE:</Text>
            <Text style={{ ...styles.textTitleTotal, fontWeight: 'bold' }}>

            </Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>IMPUESTO</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>TOTAL IMPUESTO</Text>
          </View>
          <View style={{ display: 'flex', flexDirection: 'column', gap: 10 }}>
            <Text style={styles.textTitleTotal}>TOTAL</Text>
          </View>
        </View> */}
      </Page>
    </Document>
  )
}

export default PDFOrders
