import React, { useEffect, useState } from 'react';
import { mainApi } from '../../../../store/services/mainApi'
import ContentCard from '../ContentCard/ContentCard';
import { useDispatch, useSelector } from 'react-redux'
import './SearchPage.scss';
import { CircularProgress, Switch } from '@mui/material';
import ItemCardModal from '../../ModalWindows/ItemCardModal/ItemCardModal';
import { setDataAkcent } from '../../../../store/slices/filterData';
import { VariableSizeGrid as Grid } from 'react-window';
import { setGramMode, setPriceIvaMode } from '../../../../store/slices/contextUI';
import { useParams } from 'react-router';



const SearchPage = () => {
    const tradeNameParams = useParams().tradeName
    const search = useSelector((store) => store.search.search)
    const { data: allProductsData, isLoading } = mainApi.useFindAllWordsByTradeNameQuery(tradeNameParams)
    const dispatch = useDispatch()
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [columnWidth, setColumnWidth] = useState(window.innerWidth > 768 ? 350 : 180);
    const [columnCount, setColumnCount] = useState((windowWidth / columnWidth).toFixed(0));

    useEffect(() => {
      setWindowWidth(window.innerWidth);
    }, [window.innerWidth]); 

    useEffect(() => {
      setColumnCount((windowWidth / columnWidth).toFixed(0));
    }, [window.innerWidth]); 

    const searchDataMap = useSelector((store) => store.filterData.filterDataSearch) 
    useEffect(() => {
      console.log(searchDataMap);
    }, [searchDataMap]);
    const getColumnWidth = () => columnWidth;
    const getColumnHeight = () => window.innerWidth > 768 ? 540 : 380;

      
    const ivaSwitch = useSelector((state) => state.contextUI.PriceIVA)

    return (<>
       <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px'}}>
            <div style={{display: 'flex', alignItems: 'center', gap: '10px'}}>
              <span style={{color: '#A4A4A4', marginLeft: '83px', marginBottom: '-14px'}}>IVA</span>
            </div>
            <div style={{display: 'flex', alignItems: 'center', gap: '5px'}}>
              <div style={{marginRight: '-6px'}}>
                <span style={{color: '#A4A4A4'}}>Los precios</span> sin
              </div>
              <Switch  
                className='ProfileMobile__status__switch'
                checked={ivaSwitch}
                onChange={() => {dispatch(setPriceIvaMode(!ivaSwitch))}}
                sx={{
                  width: '68px',
                  '& .MuiSwitch-root .MuiSwitch-sizeMedium': {
                      width: '66px'},
                  '& .MuiSwitch-switchBase.Mui-checked': {
                      color: '#fff !important',
                      widows: '20px',
                  },
                  '& .Mui-checked+.MuiSwitch-track': {
                      backgroundColor: '#5FC56E !important'},
                  '& .MuiSwitch-track': {

                          height: '24px',
                          width: '70px',
                          borderRadius: '14px',

                          transform: 'translateX(-5px) translateY(-5px)'
                      }
                  }}
                />
                <div style={{marginLeft: '-16px'}}>
                  сon
                </div>
            </div>
          </div>
      <div className='categoriesPage SearchPage' style={{width: '94%'}}>
        {isLoading ? <CircularProgress/> :
        <Grid
          style={{ width: '100vw' }}
          columnCount={columnCount}
          columnWidth={getColumnWidth}
          rowCount={Math.ceil((searchDataMap ? searchDataMap.length : 0) / columnCount)}
          rowHeight={getColumnHeight}
          height={window.innerHeight}
          width={windowWidth}
        >
          {({ columnIndex, rowIndex, style }) => (
          isLoading ? <CircularProgress/> : 
          searchDataMap[rowIndex * columnCount + columnIndex] === undefined ? null :<ContentCard styledMap={style} card={searchDataMap[rowIndex * columnCount + columnIndex]} key={searchDataMap[rowIndex * columnCount + columnIndex]?.name+searchDataMap[rowIndex * columnCount + columnIndex]?.sku}/>
        )}
        </Grid>}
      </div>
      </>
    );
}

export default SearchPage;